import { ChakraProvider, Box, Spinner } from '@chakra-ui/react';
import React, { Suspense, lazy, useState } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { register } from './serviceWorker';
import "./app.css"
import * as PropTypes from 'prop-types';
const New = lazy(() => import('./pages/New'));
const NewReturn = lazy(() => import('./pages/NewReturn'));
const Returns = lazy(() => import('./pages/Returns'));
const Home = lazy(() => import('./pages/Home'));
const Detail = lazy(() => import('./pages/Detail'));
const ReturnDetail = lazy(() => import('./pages/ReturnDetail'));
const Nav = lazy(() => import('./components/Navbar'));
const PinPage = lazy(() => import('./pages/PinPage'));
const SetSendCode = lazy(() => import('./pages/SetSendCode'));
const Search = lazy(() => import('./pages/Search'));
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const App = () => {
  const [login, setLogin] = React.useState(false);
  return (
    <>
      <Toaster position="top-center" />
      <ChakraProvider>
        <Suspense
          fallback={
            <>
              <Box
                w="full"
                h="100vh"
                display="flex"
                justifyContent={'center'}
                alignItems="center"
              >
                <Box boxSize={'100px'} display="flex" flexDirection={'column'}>
                  <Box display="flex" w="full" justifyContent={'center'}>
                    <Spinner size="lg" color="red.500" />
                  </Box>
                  <Box mt="2">Yükleniyor...</Box>
                  <Box mt="2">CKYMOTO</Box>
                </Box>
              </Box>
            </>
          }
        >
          <BrowserRouter>
            <Nav login={login} setLogin={setLogin} />
            <Box p="4">
              <Routes>
                <Route
                  path="/new"
                  element={<New login={login} setLogin={setLogin} />}
                />
                <Route
                  path="/"
                  element={<Home login={login} setLogin={setLogin} />}
                />
                <Route
                  path="/set-post-code"
                  element={<SetSendCode login={login} setLogin={setLogin} />}
                />
                <Route
                  path="/new-return"
                  element={<NewReturn login={login} setLogin={setLogin} />}
                />
                <Route
                  path="/returns"
                  element={<Returns login={login} setLogin={setLogin} />}
                />
                <Route path="/return-view/:id" element={<ReturnDetail />} />
                <Route path="/view/:id" element={<Detail />} />
                <Route path="/search" element={<Search />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </Suspense>
      </ChakraProvider>
    </>
  );
};
root.render(<App />);
register();
